
/*(function($) {
  // Site title
  wp.customize('blogname', function(value) {
    value.bind(function(to) {
      $('.brand').text(to);
    });
  });
})(jQuery);
*/

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, document, window) {
  'use strict';

  var $window, $body, $document;

  function assignBootstrapMode() {
    var mode;

    switch (true) {
      case $window.width() >= 1300:
        mode = 'mode-lg';
        break;
      case $window.width() >= 1024:
        mode = 'mode-md';
        break;
      case $window.width() >= 768:
        mode = 'mode-sm';
        break;
      default:
        mode = 'mode-xs';
        break;
    }

    $body.removeClass('mode-xs mode-sm mode-md mode-lg').addClass(mode);
  }

  function goToSection() {

      $('a[href*="#"]:not([data-toggle])').on('click', function (e) {

        var divId = this.href.split("#")[1], $div = $('#' + divId);

        if($div.length){
          e.preventDefault();
          $('body, html').stop().animate({scrollTop: $div.offset().top}, 500, 'swing');
        }

      });
  }

  function inithistoryBack() {

    function historyBack(e) {
      //Referrer ao dominio atual?
      if (document.referrer.indexOf(window.location.host) !== -1) {
        //Tem itens no history back?
        if (window.history.length > 1) {
            e.preventDefault();
            window.history.back();
        }
      } else {
        window.location = URL_PATH;
      }
    }

      $document.on('click', 'a[data-back-link]', historyBack);
  }


  function initMenuMobile() {

    $document.on('show.bs.collapse', '.navbar-collapse', function () {
      $('.navbar-toggle').addClass('open');
    }).on('hide.bs.collapse', '.navbar-collapse', function () {
      $('.navbar-toggle').removeClass('open');
    });

  }

  function sliderVitrineHome() {
    var $wrapper = $('.news-home__highlights'), $slide = $wrapper.find('.news-home__highlights-slide');

    if (!$wrapper.length) {
      return;
    }

    if($slide.children().length <= 1){
      return;
    }

    $slide.bxSlider({
      mode: 'horizontal',
      auto: true,
      controls: false,
      pagerSelector: $wrapper.find('.news-home__highlights-slide-pager')
    });
  }

  function initSeeMore() {

      $('[data-chourico-see-more]').on('click', function (e) {
          e.preventDefault();

          var classParent = $(this).data('chourico-see-more').substring(1);

          $(this).closest('' + $(this).data('chourico-see-more'))
              .addClass(classParent + "--seemore");

      });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        $('a[rel~=external]').attr('target', '_blank');

        $window = $(window);
        $document = $(document);
        $body = $('body');

        goToSection();

        objectFitImages();

        $.bootstrapActiveSize();

        $window.on('resize', $.throttle(150, assignBootstrapMode));
        assignBootstrapMode();

        //$('iframe[src*="youtube"]').parent().fitVids();

        $document.on('click', '[data-accessbility-control]', function (e) {
          e.preventDefault();
            $body.trigger($(this).data('accessbility-control'));
        });

        inithistoryBack();

        initSeeMore();

        $("[data-fancybox]").fancybox({
            loop: true
        });

        initMenuMobile();

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

        sliderVitrineHome();

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'fale_conosco' : {
      init: function () {

          var maskBehavior = function (val) {
              return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
          }, options = {
              onKeyPress: function (val, e, field, options) {
                  field.mask(maskBehavior.apply({}, arguments), options);
              }
          };

          $('[data-mask="phone"]').mask(maskBehavior, options);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery, document, window); // Fully reference jQuery after this point.

;(function ($, window, undefined) {
    'use strict';

    var $body,
        fontSizeIndex,
        fontSizes = [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150],
        cookieFontSize = 'fontsize',
        cookieContrast = 'contrast';

    function init() {
        change($.jStorage.get(cookieFontSize, 100));
        contrastChange($.jStorage.get(cookieContrast, false), false);
    }

    function filterSize(fontSize) {
        fontSizeIndex = fontSizes.indexOf(fontSize);
        if (fontSizeIndex === -1) {
            fontSize = 100;
            filterSize(fontSize);
        }

        return fontSize;
    }

    function change(fontSize) {
        fontSize = filterSize(fontSize);

        $body.removeClass(function (index, css) {
            return (css.match(/(^|\s)fontsize-\S+/g) || []).join(' ');
        });

        if (fontSize !== 100) {

            var cssClass = 'fontsize-' + fontSize,
                removeClass;

            $body.addClass(cssClass).removeClass(removeClass);
            $.jStorage.set(cookieFontSize, fontSize)
            //store.set(cookie, fontSize);
        } else {
            $.jStorage.deleteKey(cookieFontSize)
            //store.remove(cookie);
        }
    }

    function set(index) {
        var newFontSizeIndex = fontSizeIndex + index;
        if (fontSizes[newFontSizeIndex]) {
            fontSizeIndex = newFontSizeIndex;
            change(fontSizes[fontSizeIndex]);
        }
    }

    function reset() {
        change(100);
    }

    function increase() {
        set(1);
    }

    function decrease() {
        set(-1);
    }

    function contrastChange(contrastState, change) {

        if(contrastState) {
            $body.addClass('contrast');

            if(change) {
                $.jStorage.set(cookieContrast, true);
            }
        } else {
            $body.removeClass('contrast');

            if(change) {
                $.jStorage.deleteKey(cookieContrast);
            }
        }

    }

    function contrastListener() {
        contrastChange(!$.jStorage.get(cookieContrast, false), true);
    }

    $(function () {
        $body = $('body');

        init();

        $body
            .on('fontsize-increase', increase)
            .on('fontsize-reset', reset)
            .on('fontsize-decrease', decrease)
            .on('contrast-change', contrastListener);
    });

// /cookie functions
})(jQuery);
;(function ($, document) {

    var $document = $(document), submitText = '';

    function submitForm(e) {
        e.preventDefault();

        var $form = $(e.currentTarget),
            $submit = $form.find('input[type=submit], button').first(),
            action = $form.attr('action'),
            processor = $form.data('chourico-ajaxform'),
            url = action + 'mailer/index.php?sender=' + encodeURI(processor);

        function enableSubmit() {

            var method;

            if ($submit.is('input')) {
                method = 'val';
            } else {
                method = 'text';
            }

            $submit.prop('disabled', false);
            $submit[method](submitText);
        }

        function disableSubmit() {
            var val, method;

            if ($submit.is('input')) {
                method = 'val';
            } else {
                method = 'text';
            }

            $submit.prop('disabled', true);
            submitText = $submit[method]();

            $submit[method]('Enviando...');
        }

        function preparesAlert(data){

            var type = '',
                message = '',
                $alert = $form.children('.chourico-ajaxform-alert');

            if(!data){
                type = 'info';
                message = 'Resultado inesperado! :(';
            } else {
                type = data.messageType;
                message = data.messageText;
            }

            if (!$alert.length) {
                $alert = $('<div class="alert chourico-ajaxform-alert" />');
                $form.prepend($alert);
            }

            $alert.removeClass('alert-info alert-success alert-danger').addClass('alert-' + type).html(message);

            enableSubmit();
        }

        function onError(data) {

            if (data.responseText) {
                data.messageType = 'info';
                data.messageText = '<strong>Falha recebida ao enviar mensagem:</strong><br/><br/>' + data.responseText;
            }

            preparesAlert(data);
        }    

        function beforeSubmit() {
            if ($submit.prop('disabled')) {
                return false;
            }

            disableSubmit();
        }

        function onSuccess(data) {

            if (data.messageType === 'success') {
                $form.clearForm();
            }

            preparesAlert(data);
        }

        $form.ajaxSubmit({
            url: url,
            dataType: 'json',
            type: 'post',
            beforeSubmit: beforeSubmit,
            success: onSuccess,
            error: onError,
        });
    }

    $document.on('submit', 'form[data-chourico-ajaxform]', submitForm);
})(jQuery, document);
